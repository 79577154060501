define([
    'zepto',
    'lodash',
    'layout',
    'warmupUtils',
    'wixappsLayout/specificComponents/wixappsSingleCompLayout',
    'experiment'
], function (
    $,
    _,
    layout,
    warmupUtils,
    wixappsSingleCompLayout
) {
    'use strict';

    const proxyLayoutRegistrar = warmupUtils.proxyLayoutRegistrar;
    const inlineContent = 'inlineContent';

    function getDomNodeFunc() {
        const idsArr = _.toArray(arguments);
        const childIdString = idsArr.join('');
        const childIdWithoutRootComp = idsArr.slice(1).join(''); //this is a workaround for bug #CLNT-6844, since wixapps dont cant use the reactPageFunc to travel on the refs to get the child component
        return $(`#${childIdString}`)[0] || $(`#${childIdWithoutRootComp}`)[0];
    }

    function measureComponentChildren(structureInfo, siteData, nodesMap, measureMap) {
        wixappsSingleCompLayout.measureComponentChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, siteData);
    }

    function customMeasureComponent(proxyCompInfo, nodesMap, measureMap, siteData) {
        const compId = proxyCompInfo.compId;
        const node = getDomNodeFunc(compId);
        nodesMap[compId] = node;
        const structureInfo = proxyCompInfo.structureInfo;
        structureInfo.id = compId;
        structureInfo.type = proxyCompInfo.compType;
        measureComponentChildren(structureInfo, siteData, nodesMap, measureMap);
        if (wixappsSingleCompLayout.maps.classBasedCustomMeasures[proxyCompInfo.compType]) {
            wixappsSingleCompLayout.maps.classBasedCustomMeasures[proxyCompInfo.compType](compId, measureMap, nodesMap, proxyCompInfo.structureInfo, siteData);
        }
    }

    function measureAppPart(id, measureMap, nodesMap) {
        const inlineContentId = id + inlineContent;
        const dataState = _.get(nodesMap, [id, 'attributes', 'data-state', 'value']);

        if (warmupUtils.stringUtils.isTrue(nodesMap[id].getAttribute('data-dynamic-height')) &&
            dataState !== 'loading') {
            measureMap.height[id] = measureMap.height[inlineContentId];
            measureMap.minHeight[id] = measureMap.height[inlineContentId];
        }
    }

    function preMeasureProxies(structure, appPartNode, measureMap, nodesMap, siteData) {
        const id = structure.id;
        const inlineContentId = id + inlineContent;
        const rootNode = appPartNode.querySelector(`#${inlineContentId}`);
        measureMap.custom[id] = {};

        let needsRelayout = false;

        _.forEach(proxyLayoutRegistrar.getProxiesToMeasure(), function (measureFunction, proxyName) {
            const proxyNodes = getProxyNodesFunc(proxyName, rootNode);

            measureMap.custom[id][proxyName] = [];

            _.forEach(proxyNodes, function (node, index) {
                const proxyNodeMeasure = measureFunction(node, siteData, measureMap);
                measureMap.custom[id][proxyName][index] = proxyNodeMeasure;
                if (proxyNodeMeasure.comp) {
                    customMeasureComponent(proxyNodeMeasure.comp, nodesMap, measureMap, siteData);
                }

                if (proxyNodeMeasure.needsRelayout && !measureMap.custom[id][proxyName][index].didRelayout) {
                    needsRelayout = true;
                    measureMap.custom[id][proxyName][index].didRelayout = true;
                }
            });
        });

        const changedCompsMap = {};
        changedCompsMap[structure.id] = true;

        return {
            needsAdditionalInnerLayout: needsRelayout,
            changedCompsMap
        };
    }

    function postPatchProxies(structure, measureMap, patchers, nodesMap, siteData) {
        const id = structure.id;

        _(proxyLayoutRegistrar.getProxiesToMeasure())
            .flatMap((measureFunction, proxyName) => measureMap.custom[id][proxyName])
            .forEach(measureResult => {
                applyDomManipulations(measureResult.domManipulations);

                const compInfo = measureResult.comp;
                if (compInfo) {
                    wixappsSingleCompLayout.patchComponent(compInfo.structureInfo, patchers, nodesMap, measureMap, siteData);
                }
            });
    }

    function applyDomManipulations(domManipulations) {
        _.forEach(domManipulations, function (manipulation) {
            const node = $(manipulation.node);
            const funcName = manipulation.funcName;
            const params = _.isArray(manipulation.params) ? manipulation.params : [manipulation.params];

            if (_.isFunction(node[funcName])) {
                node[funcName].apply(node, params);
            }
        });
    }

    function getProxyNodesFunc(proxyName, contextNode) {
        return $(`[data-proxy-name=${proxyName}]`, contextNode);
    }

    layout.registerLayoutInnerCompsFirst('wixapps.integration.components.AppPart', preMeasureProxies, postPatchProxies);
    layout.registerLayoutInnerCompsFirst('wixapps.integration.components.AppPart2', preMeasureProxies, postPatchProxies);

    layout.registerRequestToMeasureChildren('wixapps.integration.components.AppPart', [[inlineContent]]);
    layout.registerRequestToMeasureDom('wixapps.integration.components.AppPart');
    layout.registerCustomMeasure('wixapps.integration.components.AppPart', measureAppPart);

    layout.registerRequestToMeasureChildren('wixapps.integration.components.AppPart2', [[inlineContent]]);
    layout.registerRequestToMeasureDom('wixapps.integration.components.AppPart2');
    layout.registerCustomMeasure('wixapps.integration.components.AppPart2', measureAppPart);

    return {
        appPartMeasureFunction: measureAppPart,
        inlineContentId: inlineContent,
        preMeasureProxies,
        postPatchProxies
    };
});
